import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { PrizeType } from '../dataTypes';

//ASYNC THUNKS
export const FETCH_PRIZES_CAMPAIGN = createAsyncThunk(
  'fetch/prizes_campaign',
  async (
    data: {
      id: string;
      page: number;
      limit: number;
      search?: string;
      game?: string;
      field_prize_distribution_type?: string;
    },
    thunkAPI
  ) => {
    const prizePath =
      (data.field_prize_distribution_type === 'prize_stock' ||
      data.field_prize_distribution_type === 'result_based') && data.game === 'quiz_campaign'
        ? '/quiz'
        : '';

    const response = await apiDrupal.get(
      `${base_url}/hub/v2/competition/${data.id}${prizePath}/prizes?page=${data.page}&page_size=${data.limit
      }&search=${data.search || ''}`
    );

    const beeRunPrizes = await apiDrupal.get(
      `${base_url}/jsonapi/cm_collect_objective_prize/bee_run?filter[status][value]=1&include=prize.field_image&page[limit]=${data.limit}&page[offset]=${data.page}&filter[campaign.id][condition][path]=campaign.meta.drupal_internal__target_id&filter[campaign.id][condition][operator]==&filter[campaign.id][condition][value]=${data.id}`
    );

    const combinedData = {
      ...response.data,
      beeRun: beeRunPrizes.data
    };

    return combinedData;
  }
);

export const FETCH_PRIZE_CAMPAIGN_ByID = createAsyncThunk(
  'fetch/prizes_campaign_ByID',
  async (data: { campaignId: string; id: string; game: string }, thunkAPI) => {
    if (data.game === 'bee_run') {
      const beeRunResponse = await apiDrupal.get(`${base_url}/jsonapi/cm_collect_objective_prize/bee_run/${data.id}?include=prize`);
      return beeRunResponse.data;
    } else {
    const response = await apiDrupal.get(
      `${base_url}/hub/v2/competition/${data.campaignId}/prizes/${data.id}`
    );
    return response.data;
    }
  }
);

export const STORE_PRIZES_CAMPAIGN = createAsyncThunk(
  'store/prizes_campaign',
  async (data: { id: string; prizeData: PrizeType; campaignUuid?: string }, thunkAPI) => {
    if (data.prizeData.type === 'bee_run') {
      const response = await apiDrupal.post(`${base_url}/jsonapi/cm_collect_objective_prize/bee_run`, {
        "data": {
          "type": "cm_collect_objective_prize--bee_run",
          "attributes": {
            "quantity": `${data.prizeData.quantity}`,
            "status": true,
            "prize_description": `${data.prizeData.prize_description}`,
            "winner_redirect_link": {
              "uri": `${data.prizeData.winner_redirect_link.uri}`,
              "title": `${data.prizeData.winner_redirect_link.title}`
            },
            "default_langcode": true
          },
          "relationships": {
            "cm_collect_objective_prize_type": {
              "data": null
            },
            "campaign": {
              "data": {
                "type": "cm_base_campaign--bee_run",
                  "id": `${data.campaignUuid}`
              }
            },
            "prize": {
              "data": {
                "type": "cm_prize--cm_prize",
                "id": `${data.prizeData.prizeId}`
              }
            }
          }
        }
      },{
        headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: '*/*'
        }
      }
      ).then(response => response.data).catch(error => console.log(error));

      return response.data;
      } else {
        let endpoint = `${base_url}/hub/v2/competition/${data.id}`;

        if ((data.prizeData.field_prize_distribution_type === "prize_stock" || data.prizeData.field_prize_distribution_type === "result_based") && data.prizeData.type === "quiz_campaign") {
          endpoint += "/quiz";
        }

        endpoint += "/prizes";

        const response = await apiDrupal.post(endpoint, {
          country: '1',
          type: data?.prizeData?.type,
          campaign: data.id,
          prize: data.prizeData.prize,
          prize_description: data.prizeData.prize_description,
          quantity: data.prizeData.quantity,
          initial_quantity: data.prizeData.quantity,
          label: data.prizeData.field_spin_prize_name,
          field_spin_prize_bg_color: data.prizeData.field_spin_prize_bg_color,
          field_spin_prize_text_color: data.prizeData.field_spin_prize_text_color,
          field_spin_prize_name: data.prizeData.field_spin_prize_name,
          winner_redirect_link: data.prizeData.winner_redirect_link,
        });

    if (data.prizeData.type === 'spinning_wheel') {
      const file = data.prizeData.field_image[0].file;
      const reader = new FileReader();

      reader.onloadend = async () => {
        const binaryString = reader.result;

        try {
          const postPrizeImage = await apiDrupal.post(
            `${base_url}/jsonapi/cm_time_window_prize/spinning_wheel/${response.data.data.prize.uuid}/field_spin_prize_image`,
            binaryString,
            {
              headers: {
                'Content-Type': 'application/octet-stream',
                'Accept': 'application/vnd.api+json',
                'Content-Disposition': 'file; filename="prize-image.jpg"'
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      };

      reader.readAsArrayBuffer(file);
    }

    return response
  }
  }
);

export const PATCH_PRIZES_CAMPAIGN = createAsyncThunk(
  'patch/prizes_campaign',
  async (data: { campaignId: string; id: string; prizeData: PrizeType }, thunkAPI) => {
    if (data.prizeData.type === 'bee_run') {
    const response = await apiDrupal.patch(`${base_url}/jsonapi/cm_collect_objective_prize/bee_run/${data.prizeData.id}`, {
      "data": {
        "type": "cm_collect_objective_prize--bee_run",
        "id": `${data.prizeData.id}`,
        "attributes": {
          "quantity": `${data.prizeData.quantity}`,
          "status": true,
          "prize_description": `${data.prizeData.prize_description}`,
          "winner_redirect_link": {
            "uri": `${data.prizeData.winner_redirect_link.uri}`,
            "title": `${data.prizeData.winner_redirect_link.title}`
          },
          "default_langcode": true
        },
        "relationships": {
          "cm_collect_objective_prize_type": {
            "data": null
          },
          "campaign": {
            "data": {
              "type": "cm_base_campaign--bee_run",
                "id": `${data.prizeData.campaign}`
            }
          },
          "prize": {
            "data": {
              "type": "cm_prize--cm_prize",
              "id": `${data.prizeData.prizeId}`
            }
          }
        }
      }
    },{
      headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: '*/*'
      }
    }
    ).then(response => response.data).catch(error => console.log(error));

    return response.data;
    } else {
      let endpoint;
      let body;

      if ((data.prizeData.field_prize_distribution_type === "prize_stock" || data.prizeData.field_prize_distribution_type === "result_based") && data.prizeData.type === "quiz_campaign") {
        endpoint = `${base_url}/hub/v2/competition/${data.campaignId}/quiz/prizes/${data.id}`;
        body = {
          campaign: data.campaignId,
          prize: data.prizeData.prize,
          initial_quantity: data.prizeData.quantity,
          label: data.prizeData.field_spin_prize_name,
          prize_description: data.prizeData.prize_description,
        };
      } else {
        endpoint = `/campaigns/${data.campaignId}/prizes/${data.id}`;
        body = {
          prize: data.prizeData.prize,
          field_spin_prize_name: data.prizeData.field_spin_prize_name,
          type: data?.prizeData?.type,
          quantity: data.prizeData.quantity,
          prize_description: data.prizeData.prize_description,
          field_spin_prize_bg_color: data.prizeData.field_spin_prize_bg_color,
          field_spin_prize_text_color: data.prizeData.field_spin_prize_text_color,
          winner_redirect_link: data.prizeData.winner_redirect_link,
        };
      }

      const response = await apiDrupal.patch(endpoint, body );
      return response.data;
  }
  }
);

export const DELETE_PRIZE_CAMPAIGN = createAsyncThunk(
  'delete/prize_campaign',
  async (data: { campaignId: string; id: string, game?: string, field_prize_distribution_type?: string }, thunkAPI) => {
    if (data.game === 'bee_run') {
      const beeRunResponse = await apiDrupal.delete(`${base_url}/jsonapi/cm_collect_objective_prize/bee_run/${data.id}`);
      return beeRunResponse.data;
    } else {
      let endpoint;

      if ((data.field_prize_distribution_type === "prize_stock" || data.field_prize_distribution_type === "result_based") && data.game === "quiz_campaign") {
        endpoint = `${base_url}/hub/v2/competition/${data.campaignId}/quiz/prizes/${data.id}`;
      } else {
        endpoint = `/campaigns/${data.campaignId}/prizes/${data.id}`;
      }

      const response = await apiDrupal.delete(endpoint);
      return response.data;
    }
  }
);

interface IPrizeState {
  data: PrizeType[];
  prizeData: PrizeType;
  total: number;
  error: any;
}

const initialState: IPrizeState = {
  data: [],
  prizeData: undefined,
  total: 0,
  error: undefined,
};

export const Slice = createSlice({
  name: 'PrizeCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_PRIZES_CAMPAIGN
    builder.addCase(FETCH_PRIZES_CAMPAIGN.pending, (state, action) => { });
    builder.addCase(FETCH_PRIZES_CAMPAIGN.fulfilled, (state, action) => {
      if (action.meta.arg.game === 'bee_run') {
        state.data = Object.values(action.payload.beeRun.data);
        state.total = action.payload.beeRun.meta.count;

        for (let i = 0; i < state.data.length; i++) {
          state.data[i].id = action.payload.beeRun.data[i].id;
          state.data[i].prizeId = action.payload.beeRun.data[i].relationships.prize.data.id;
          state.data[i].description = action.payload.beeRun.data[i].attributes.prize_description;
          state.data[i].winnerURL = action.payload.beeRun.data[i].attributes.winner_redirect_link?.uri;
          state.data[i].labelURL = action.payload.beeRun.data[i].attributes.winner_redirect_link?.title;
          state.data[i].quantity = action.payload.beeRun.data[i].attributes.quantity;
          const prizeObj = action.payload.beeRun.included.find(
            included => included.id === state.data[i].prizeId && included.type === 'cm_prize--cm_prize'
          );
          if (prizeObj && prizeObj.relationships.field_image && prizeObj.relationships.field_image.data) {
            const imageObj = action.payload.beeRun.included.find(
              included => included.type === 'file--file' && included.id === prizeObj.relationships.field_image.data.id
            );

            if (imageObj) {
              const imageUrl = imageObj.attributes.uri.url;
              state.data[i].preview = (
                <img
                  src={base_url + imageUrl}
                  alt={imageObj.attributes.alt}
                  title={imageObj.attributes.title}
                  width={50}
                  height={50}
                />
              );
            }
          }
          for (let j = 0; j < action.payload.beeRun.included.length; j++) {
            if (state.data[i].prizeId === action.payload.beeRun.included[j].id) {
              state.data[i].name = action.payload.beeRun.included[j].attributes.name;
              state.data[i].category = action.payload.beeRun.included[j].attributes.field_type;
              state.data[i].status = action.payload.beeRun.included[j].attributes.status === true ? 'published' : 'unpublished';
              break;
            }
          }
        }
      } else {
      state.data = Object.values(action.payload.data.prizes);
      state.total = action.payload.data.total_items;

      let prizes = state.data;
      let aux = state.data;

      //Add HTML IMG element with url and add it to the status field.
      for (let index = 0; index < prizes.length; index++) {
        const element = prizes[index];

        aux[index].name = element?.prize?.name;
        aux[index].category = element?.prize?.field_type;
        aux[index].preview = (
          <img
            src={base_url + element?.prize?.field_image?.url}
            alt={element?.prize?.field_image?.alt}
            title={element?.prize?.field_image?.title}
            width={50}
            height={50}
          />
        );
        aux[index].status = element?.status === true ? 'published' : 'unpublished';
        aux[index].winnerURL = element?.winner_redirect_link?.uri;
        aux[index].labelURL = element?.winner_redirect_link?.title;
      }

      state.data = aux;
      }
    });
    builder.addCase(FETCH_PRIZES_CAMPAIGN.rejected, (state, action) => {
      state.error = action.error;
    });

    //FETCH_PRIZE_CAMPAIGN_ByID
    builder.addCase(FETCH_PRIZE_CAMPAIGN_ByID.pending, (state, action) => { });
    builder.addCase(FETCH_PRIZE_CAMPAIGN_ByID.fulfilled, (state, action) => {

      if (action.meta.arg.game === 'bee_run') {
        state.prizeData = action.payload.data.attributes;

        let prize = state.prizeData;
        let aux = state.prizeData;

        aux.id = action.payload.data.id;
        aux.prizeId = action.payload.included[0].id;
        aux.campaign = action.payload.data.relationships.campaign.data.id;
        aux.name = action.payload.included[0].attributes.name;
        aux.category = action.payload.included[0].attributes.field_type;
        aux.prize = action.payload.included[0].attributes.drupal_internal__id;
        aux.status = prize?.status === true ? 'published' : 'unpublished';
        aux.winnerURL = prize?.winner_redirect_link?.uri;
        aux.labelURL = prize?.winner_redirect_link?.title;

        state.prizeData = aux;
      } else {
      state.prizeData = action.payload.data.prize;

      let prize = state.prizeData;
      let aux = state.prizeData;

      aux.name = prize?.prize?.name;
      aux.category = prize?.prize?.field_type;
      aux.preview = (
        <img
          src={prize?.prize?.field_image?.url}
          alt={prize?.prize?.field_image?.alt}
          title={prize?.prize?.field_image?.title}
          width={50}
          height={50}
        />
      );
      aux.status = prize?.status === true ? 'published' : 'unpublished';
      aux.winnerURL = prize?.winner_redirect_link?.uri;
      aux.labelURL = prize?.winner_redirect_link?.title;

      state.prizeData = aux;
      }
    });
    builder.addCase(FETCH_PRIZE_CAMPAIGN_ByID.rejected, (state, action) => {
      state.error = action.error;
    });

    //STORE_PRIZES_CAMPAIGN
    builder.addCase(STORE_PRIZES_CAMPAIGN.pending, (state, action) => { });
    builder.addCase(STORE_PRIZES_CAMPAIGN.fulfilled, (state, action) => { });
    builder.addCase(STORE_PRIZES_CAMPAIGN.rejected, (state, action) => {
      state.error = action.error;
    });

    //PATCH_PRIZES_CAMPAIGN
    builder.addCase(PATCH_PRIZES_CAMPAIGN.pending, (state, action) => { });
    builder.addCase(PATCH_PRIZES_CAMPAIGN.fulfilled, (state, action) => { });
    builder.addCase(PATCH_PRIZES_CAMPAIGN.rejected, (state, action) => {
      state.error = action.error;
    });

    //DELETE_PRIZE_CAMPAIGN
    builder.addCase(DELETE_PRIZE_CAMPAIGN.pending, (state, action) => { });
    builder.addCase(DELETE_PRIZE_CAMPAIGN.fulfilled, (state, action) => { });
    builder.addCase(DELETE_PRIZE_CAMPAIGN.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
