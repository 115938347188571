import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiDrupal } from '../../Api/Api';
import { CampaignType } from '../dataTypes';
import { BottleCrushType } from '../dataTypes/bottleCrush.type';

//ASYNC THUNKS
export const FETCH_CAMPAIGN_DETAILS = createAsyncThunk(
  'fetch/campaignDetails',
  async (
    data: {
      id: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get('/campaigns/' + data.id);
    return response.data;
  }
);

export const STORE_CAMPAIGN_DETAILS = createAsyncThunk(
  'store/campaignDetails',
  async (data: CampaignType, thunkAPI) => {
    let campaignData = {
      country: data.country,
      name: data.name,
      type: data.type.target_id,
      brand: data.brand.target_id,
      category: data.category.target_id,
      period: {
        value: data.period.start + ':00',
        end_value: data.period.end + ':00',
      },
      lucky_number_status: data.lucky_number_status,
      rewards_integration: data.rewards_integration,
      /* bee_run_prizes: data.bee_run_prizes, */
      status: data.status === 'Published' ? true : false,
    };

    let mixnmatchData = {
      type: 'bottle_crush',
      field_brand: data.brand,
      field_bottle_crush: {
        name: data.name_mix_and_match,
        player_progress_expiration_time: data.player_progress_expiration_time,
        new_game: !!data.newgame,
      },
    };

    let quizData = {
      field_prize_distribution_type: data.field_prize_distribution_type,
    }

    let soccerCompetitionData = {
      tournament_name: data.tournament_name,
      max_minutes: data.max_minutes_to_guess,
      rank_type: data.rank_type,
      guess_by: data.guess_by,
      guess_type: data.guess_type,
    };

    campaignData = {
      ...campaignData,
      ...(data.type.target_id === 'bottle_crush'
        ? mixnmatchData
        : data.type.target_id === 'soccer_competition'
          ? soccerCompetitionData
          : data.type.target_id === 'quiz_campaign'
          ? quizData
          : {}),
    };

    const response = await apiDrupal.post('/campaigns', campaignData);
    return response.data;
  }
);

export const PATCH_CAMPAIGN_DETAILS = createAsyncThunk(
  'patch/campaignDetails',
  async (data: CampaignType, thunkAPI) => {
    let campaignData = {
      country: data.country,
      name: data.name,
      type: data.type.target_id,
      brand: data.brand.target_id,
      category: data.category.target_id,
      period: {
        value: data.period.start,
        end_value: data.period.end,
      },
      lucky_number_status: data.lucky_number_status,
      rewards_integration: data.rewards_integration,
      /* bee_run_prizes: data.bee_run_prizes, */
      status: data.status === 'Published' ? true : false,
      accept_terms: data.accept_terms,
      consent_to_usage: data.consent_to_usage,
    };

    let quizData = {
      field_prize_distribution_type: data.field_prize_distribution_type,
    }

    let mixnmatchData = {
      type: 'bottle_crush',
      field_brand: data.brand,
      field_bottle_crush: {
        name: data.name_mix_and_match,
        player_progress_expiration_time: data.player_progress_expiration_time,
        new_game: data.newgame,
      },
    };

    let soccerCompetitionData = {
      tournament_name: data.tournament_name,
      max_minutes: data.max_minutes_to_guess,
      rank_type: data.rank_type,
      guess_by: data.guess_by,
      guess_type: data.guess_type,
    };

    campaignData = {
      ...campaignData,
      ...(data.type.target_id === 'bottle_crush'
        ? mixnmatchData
        : data.type.target_id === 'soccer_competition'
          ? soccerCompetitionData
          : data.type.target_id === 'quiz_campaign'
          ? quizData
          : {}),
    };

    const response = await apiDrupal.patch('/campaigns/' + data.id, campaignData);
    return response.data;
  }
);

export const DELETE_CAMPAIGN_DETAILS = createAsyncThunk(
  'delete/campaignDetails',
  async (
    data: {
      id: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.delete('/campaigns/' + data.id);
    return response.data;
  }
);

interface ICampaignDetailsState {
  data: CampaignType;
  newCampaign: CampaignType;
  selectedGame: string;
  bottleCrush: BottleCrushType;
  error: any;
}

const initialState: ICampaignDetailsState = {
  data: undefined,
  newCampaign: undefined,
  selectedGame: null,
  bottleCrush: {
    id: null,
    name: null,
    player_progress_expiration_time: 1,
    new_game: null,
    uuid: null,
    created: null,
    changed: null
  },
  error: undefined,
};

export const Slice = createSlice({
  name: 'CampaignDetails',
  initialState,
  reducers: {
    clearNewCampaign(state) {
      state.newCampaign = initialState.newCampaign;
      state.bottleCrush = initialState.bottleCrush;
    },
  },
  extraReducers: (builder) => {
    //FETCH_MANAGEASSETS
    builder.addCase(FETCH_CAMPAIGN_DETAILS.pending, (state, action) => {
      state.data = null;
      state.bottleCrush = null;
    });
    builder.addCase(FETCH_CAMPAIGN_DETAILS.fulfilled, (state, action) => {
      state.data = action.payload.data?.campaign;
      state.selectedGame = action?.payload?.data?.campaign?.type?.target_id;
      state.bottleCrush = action.payload.data?.bottle_crush;

      let aux: CampaignType = state.data;

      const campaign: CampaignType = state.data;

      aux.status = campaign?.status === true ? 'Published' : 'Unpublished';
      aux.fieldbrand = campaign?.brand?.name;
      aux.fieldperiod = `Date: ${moment(campaign?.period?.start).format('DD/MM/YYYY')} - ${moment(
        campaign?.period?.end
      ).format('DD/MM/YYYY')}`;
      aux.changed = moment(campaign?.changed).format('DD/MM/YYYY');
      aux.game = campaign?.type?.target_id
        ?.replace(/_/g, ' ')
        .replace('bottle crush', 'mix and match');

      state.data = aux;
    });
    builder.addCase(FETCH_CAMPAIGN_DETAILS.rejected, (state, action) => {
      state.error = action.error;
    });

    //STORE_CAMPAIGN_DETAILS
    builder.addCase(STORE_CAMPAIGN_DETAILS.pending, (state, action) => { });
    builder.addCase(STORE_CAMPAIGN_DETAILS.fulfilled, (state, action) => {
      state.newCampaign = action.payload.data?.campaign;
      state.bottleCrush = action.payload.data?.bottle_crush;
      state.newCampaign.field_prize_distribution_type = action.payload.data?.field_prize_distribution_type || '';
    });
    builder.addCase(STORE_CAMPAIGN_DETAILS.rejected, (state, action) => {
      state.error = action.error;
    });

    //PATCH_CAMPAIGN_DETAILS
    builder.addCase(PATCH_CAMPAIGN_DETAILS.pending, (state, action) => { });
    builder.addCase(PATCH_CAMPAIGN_DETAILS.fulfilled, (state, action) => {
      state.data = action.payload.data.campaign;
      state.newCampaign = action.payload.data.campaign;
      state.bottleCrush = action.payload.data?.bottle_crush;
      state.newCampaign.field_prize_distribution_type = action.payload.data?.field_prize_distribution_type || '';
    });
    builder.addCase(PATCH_CAMPAIGN_DETAILS.rejected, (state, action) => {
      state.error = action.error;
    });

    //DELETE_CAMPAIGN_DETAILS
    builder.addCase(DELETE_CAMPAIGN_DETAILS.pending, (state, action) => { });
    builder.addCase(DELETE_CAMPAIGN_DETAILS.fulfilled, (state, action) => { });
    builder.addCase(DELETE_CAMPAIGN_DETAILS.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { clearNewCampaign } = Slice.actions;
export default Slice.reducer;
