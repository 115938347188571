import { FileValidated, Grid, IconButton, Tooltip } from '@hexa-ui/components';
import { Edit2, HelpCircle, Plus, Trash2 } from '@hexa-ui/icons';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoComplete from '../../../../../components/AutoComplete/AutoComplete';
import StyledButton from '../../../../../components/Button/Button';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import ShowColor from '../../../../../components/ShowColor/ShowColor';
import StyledTable from '../../../../../components/Table/Table';
import StyledTextarea from '../../../../../components/Textarea/Textarea';
import { AlertContext } from '../../../../../contexts/alert.context';
import { DeleteModalContext } from '../../../../../contexts/deleteModal.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import useWindowDimensions from '../../../../../hook/useWindowDimensions';
import useWindowScale from '../../../../../hook/useWindowScale';
import WindowFocusHandler from '../../../../../hook/windowFocusHandler';
import { AppDispatch, useAppSelector } from '../../../../../store';
import { CampaignType, PrizeType, TimeWindowType } from '../../../../../store/dataTypes';
import {
  FETCH_PRIZES_CAMPAIGN,
  PATCH_PRIZES_CAMPAIGN,
  STORE_PRIZES_CAMPAIGN,
} from '../../../../../store/stock/PrizeCampaignReducer';
import Headers from '../../../../json/columnHeaders/PrizeListHeaders.json';
import HeadersSpinning from '../../../../json/columnHeaders/PrizeListHeadersSpinning.json';
import useTimeWindowHandler from '../../../../../hook/useTimeWindowHandler';
import { apiDrupal } from '../../../../../Api/Api';
import { base_url } from '../../../../../utils/constants';
import moment from 'moment';
import QuantifierLabeled from '../../../../../components/QuantifierLabeled/QuantifierLabeled';
import StyledFileUploader from '../../../../../components/FileUploader/FileUploader';
import verifyImgType from '../../../../../utils/verifyImgType';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPrize: Dispatch<SetStateAction<PrizeType>>;
  scrollRef: any;
}
const TabPrizeList: React.FC<props> = ({ form, setForm, setDeleteModalOpen, setSelectedPrize, scrollRef }) => {
  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);
  const { deleteModalState, setDeleteModalState } = useContext(DeleteModalContext);
  const { addToast } = useContext(AlertContext);
  const [formPrize, setFormPrize] = useState<PrizeType>();
  const isAugmentedScale = useWindowScale();
  const { width } = useWindowDimensions();
  const [winnerURLError, setWinnerURLError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [fileError, setFileError] = useState(false);
  const typelist = ['png', 'jpg', 'jpeg'];

  const [editMode, setEditMode] = useState(false);

  const [filteredPrizeData, setFilteredPrizeData] = useState<PrizeType[]>();

  const dispatch = useDispatch<AppDispatch>();
  const prizesData = useAppSelector((state) => state.prizeCampaign.data);

  const [loading, setLoading] = useState(false);
  const { getAllTimeWindow } = useTimeWindowHandler();
  const [timeWindowData, setTimeWindowData] = useState<TimeWindowType[]>();

  const regexHTTP = /^(http:\/\/|https:\/\/).{1,}$/i;

  const handleSavePrize = async () => {
    if (
      !gameSettingsState?.prizeList.addPrize ||
      !gameSettingsState?.prizeList.addPrize?.quantity ||
      (form?.type?.target_id === 'spinning_wheel' &&
        (!gameSettingsState?.prizeList.addPrize.field_spin_prize_name ||
          !gameSettingsState?.prizeList.addPrize?.field_spin_prize_bg_color?.color ||
          !gameSettingsState?.prizeList.addPrize?.field_spin_prize_text_color?.color))
    ) {
      setHasEmptyField(true);
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      return;
    }

    if (!gameSettingsState?.prizeList.addPrize?.prize) {
      addToast({
        message: 'Please, select a prize to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    if (
      gameSettingsState?.prizeList.addPrize?.winner_redirect_link?.uri &&
      !regexHTTP.test(gameSettingsState?.prizeList.addPrize?.winner_redirect_link?.uri)
    ) {
      addToast({
        message: "Please insert winner's redirect link start with 'http://' or 'https://'.",
        type: 'error',
      });
      setHasEmptyField(true);
      setWinnerURLError(true);
      return;
    } else {
      setWinnerURLError(false);
    }

    if (form?.type?.target_id === 'spinning_wheel' && prizesData.length >= 11) {
      addToast({
        message: "Spinning Wheel game has a maximum limit of 11 types of prizes.",
        type: 'error',
      });
      return;
    }

    if (checkTimeWindow(gameSettingsState?.prizeList?.addPrize)) {
      const matchingTimeWindows = timeWindowData.filter(window => window.name === gameSettingsState?.prizeList.addPrize.name);

      const latestTimeWindowCreated = timeWindowData.reduce((latest, window) => {
        const windowCreatedDate = new Date(window.created);
        windowCreatedDate.setHours(windowCreatedDate.getHours() - 3);
        return latest > windowCreatedDate ? latest : windowCreatedDate;
      }, new Date(0));

      const filteredPrizesData = prizesData.filter(prize => {
        const prizeCreatedDate = new Date(prize.created);
        return prize.name === gameSettingsState?.prizeList.addPrize.name && prize.id !== gameSettingsState?.prizeList.addPrize.id && prizeCreatedDate < latestTimeWindowCreated;
      });

      const totalQuantity = filteredPrizesData.reduce((sum, prize) => sum + prize.quantity, 0) + gameSettingsState?.prizeList.addPrize.quantity;

      if (totalQuantity < matchingTimeWindows.length) {
        addToast({
          message: "Some of the prizes you've created do not match the quantity of time windows assigned for those prizes.",
          type: 'error',
        });
        return;
      }
    }

    setHasEmptyField(false);
    setLoading(true);
    gameSettingsState.prizeList.editMode
      ? await dispatch(
        PATCH_PRIZES_CAMPAIGN({
          campaignId: String(form.id),
          id: gameSettingsState?.prizeList.addPrize.id,
          prizeData: { ...gameSettingsState?.prizeList.addPrize, type: form.type.target_id, campaign: form.uuid, field_prize_distribution_type: form.field_prize_distribution_type }
        })
      )
        .then(() => {
          addToast({
            message: 'prize successfuly updated.',
            type: 'success',
          });
          handleClear();
        })
        .catch(() => {
          addToast({
            message: 'An error occurred while editing the prize.',
            type: 'error',
          });
        })
        .finally(() => setLoading(false))
      : await dispatch(
        STORE_PRIZES_CAMPAIGN({
          id: String(form?.id),
          campaignUuid: form.uuid,
          prizeData: { ...gameSettingsState?.prizeList.addPrize, type: form.type.target_id, field_prize_distribution_type: form.field_prize_distribution_type },
        })
      )
        .then(() => {
          addToast({
            message: 'prize successfuly added.',
            type: 'success',
          });
          handleClear();
        })
        .catch(() =>
          addToast({
            message: 'An error occurred while adding the prize.',
            type: 'error',
          })
        )
        .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setGameSettingsState({
      ...gameSettingsState,
      prizeList: {
        search: null,
        addPrize: {
          id: null,
          campaign: null,
          prize: null,
          field_spin_prize_name: null,
          quantity: 1,
          field_spin_prize_bg_color: null,
          field_spin_prize_text_color: null,
          image: [],
          field_image: null,
          winner_redirect_link: {
            uri: '', title: '',
            options: []
          },
          prize_description: '',
        },
        editMode: false,
      },
    });
    handleSearchAll();
  };

  const handleSearchAll = () => {
    setLoading(true);
    dispatch(
      FETCH_PRIZES_CAMPAIGN({
        page: 0,
        limit: 25,
        id: String(form?.id),
        search: '',
        game: form?.type?.target_id,
        field_prize_distribution_type: form.field_prize_distribution_type,
      })
    ).finally(() => setLoading(false));
  };

  const changeGameSettingsState = (key: string, value: any) => {
    setGameSettingsState({
      ...gameSettingsState,
      prizeList: {
        ...gameSettingsState?.prizeList,
        addPrize: {
          ...gameSettingsState?.prizeList?.addPrize,
          [key]: value,
        },
      },
    });
  };

  WindowFocusHandler(() => {
    checkIfHasTimeWindow()
    if (!form?.id) {
      addToast({
        message: 'Please create a campaign to continue.',
        type: 'error',
      });
      return;
    }

    handleClear();
    setLoading(true);

    dispatch(
      FETCH_PRIZES_CAMPAIGN({
        id: String(form?.id),
        page: 0,
        limit: 0,
        search: '',
        field_prize_distribution_type: form.field_prize_distribution_type,
      })
    ).finally(() => setLoading(false)),
      null,
      [];
  });

  const checkIfHasTimeWindow = async() => {
    setLoading(true);

    const [timeWindowResponse] = await Promise.all([
      getAllTimeWindow(form.id),
      apiDrupal.get(`${base_url}/hub/v2/competition/${form.id}/prizes`),
    ]);

    const timeWindowData = timeWindowResponse.data.data

    setTimeWindowData(timeWindowData);
    setLoading(false);
  };

  const InsertBgColor = (data) => {
    if (data) {
      let insertBgColor: any[] = [...data];
      insertBgColor = insertBgColor.map((item) => ({
        ...item,
        field_spin_prize_bg_color: <ShowColor color={item?.field_spin_prize_bg_color?.color} />,
        field_spin_prize_text_color: <ShowColor color={item?.field_spin_prize_text_color?.color} />,
      }));
      return insertBgColor;
    }
    return [];
  };

  useEffect(() => {
    let data: PrizeType[] = InsertActions(prizesData);
    setFilteredPrizeData(data);
  }, [prizesData, timeWindowData]);

  const checkTimeWindow = (prize: PrizeType) => {
    timeWindowData?.sort((a, b) => {
      const dateA:any = new Date(a.created);
      const dateB:any = new Date(b.created);
      return dateB - dateA;
    });

    const mostRecentTimeWindow = timeWindowData?.[0];

    if (timeWindowData?.some(timeItem => timeItem.name === prize?.name) && moment.utc(prize?.created).format('DD/MM/YYYY-HH:mm:ss') < moment(mostRecentTimeWindow?.created).format('DD/MM/YYYY-HH:mm:ss')) {
      return true
    }

    return false
  }

  const sizeValidation = (file: FileValidated[]) => {
    if (file[0].file.size > 102400) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setGameSettingsState({ ...gameSettingsState, prizeList: { ...gameSettingsState.prizeList, addPrize: { ...gameSettingsState.prizeList.addPrize, image: e, field_image: e } } });
    }
  };

  const InsertActions = (data: PrizeType[]) => {
    let aux: PrizeType[] = [];

    const actions = (prize: PrizeType) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                editMode: true,
                search: prize.name,
                addPrize: {
                  ...gameSettingsState.prizeList.addPrize,
                  id: prize.id,
                  campaign: prize.campaign,
                  name: prize.name,
                  created: prize.created,
                  prize: form.type.target_id === 'bee_run' ? prize?.id : prize?.prize?.id,
                  prizeId: prize?.prizeId,
                  field_spin_prize_name: ((form?.field_prize_distribution_type === "prize_stock" || form?.field_prize_distribution_type === "result_based") && form?.type?.target_id === "quiz_campaign") ? prize?.label : prize?.field_spin_prize_name,
                  quantity: ((form?.field_prize_distribution_type === "prize_stock" || form?.field_prize_distribution_type === "result_based") && form?.type?.target_id === "quiz_campaign") ? prize?.initial_quantity : prize?.quantity,
                  field_spin_prize_bg_color: prize?.field_spin_prize_bg_color,
                  field_spin_prize_text_color: prize?.field_spin_prize_text_color,
                  winner_redirect_link: form.type.target_id === 'bee_run' ? {
                    options: [],
                    title: prize?.labelURL || '',
                    uri: prize?.winnerURL || ''
                  } : prize.winner_redirect_link,
                  prize_description: form.type.target_id === 'bee_run' ? prize?.description || '' : prize?.prize_description,
                  },
                },
              });
          }}
        />
        <IconButton
          disabled={checkTimeWindow(prize)}
          icon={Trash2}
          variant="inherit"
          onClick={() => {
            setDeleteModalState({
              ...deleteModalState,
              isOpen: true,
              prize: prize,
              type: 'prizecampaign',
            });
          }}
        />
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item: PrizeType = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  useEffect(() => {
    setLoading(deleteModalState.loading);
  }, [deleteModalState.loading]);

  return (
    <Grid.Item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      style={{ rowGap: '1rem', flexDirection: 'column', padding: '0px' }}
    >
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          width: '100%',
          gap: '1rem',
          margin: '0 0 2rem 0',
          flexWrap: 'wrap',
          alignItems: isAugmentedScale ? '' : 'flex-end',
          position: 'relative',
          display: 'flex',
        }}
      >
        <AutoComplete
          onChange={(e) => {
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                search: e.currentTarget.value,
              },
            });
          }}
          onClear={() => {
            changeGameSettingsState('prize', undefined);
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                search: '',
                addPrize: {
                  ...gameSettingsState.prizeList.addPrize,
                  prize: null,
                },
              },
            });
          }}
          onClickResult={(prize) => {
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                addPrize: {
                  ...gameSettingsState.prizeList.addPrize,
                  prize: prize?.id,
                  prizeId: prize?.uuid,
                },
                search: prize.name,
              },
            });
          }}
          value={gameSettingsState?.prizeList?.search || ''}
          required
          size="large"
          label="Prize*"
          disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
          hint="Prizes are registered in the Prizes page"
          placeholder="Start typing to search"
          hasError={hasEmptyField && !gameSettingsState?.prizeList?.addPrize?.prize}
          errorText="Prize is required."
        />
        {form?.type?.target_id === 'spinning_wheel' || ((form?.field_prize_distribution_type === "prize_stock" || form?.field_prize_distribution_type === "result_based") && form?.type?.target_id === "quiz_campaign") ? (
          <StyledInput
            value={gameSettingsState?.prizeList.addPrize?.field_spin_prize_name || ''}
            required
            size="large"
            label="Prize name*"
            hint="Prizes are registered in the Prizes page"
            placeholder="Start typing to search"
            disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
            onChange={(e) =>
              changeGameSettingsState('field_spin_prize_name', e.currentTarget.value)
            }
            hasError={
              hasEmptyField && !gameSettingsState?.prizeList.addPrize?.field_spin_prize_name
            }
            errorText="Prize name is required."
          />
        ) : null}
        <QuantifierLabeled
          title="Quantity"
          labelText="How many units of this prize are available"
          defaultValue={gameSettingsState?.prizeList.addPrize?.quantity}
          value={gameSettingsState?.prizeList.addPrize?.quantity}
          min={1}
          disabled={(form?.field_prize_distribution_type === 'prize_stock' || form?.field_prize_distribution_type === 'result_based') && gameSettingsState.prizeList.editMode && form?.type?.target_id === 'quiz_campaign'}
          onChange={(e) => {
            let v = parseInt(e.currentTarget.value, 10);
            if (isNaN(v)) {
              v = 1;
            }
            v = v <= 0 ? 1 : v;
            changeGameSettingsState('quantity', v);
          }}
          onClickPlusButton={() => {
            changeGameSettingsState('quantity', gameSettingsState?.prizeList.addPrize?.quantity + 1);
          }}
          onClickMinusButton={() => {
            changeGameSettingsState('quantity', gameSettingsState?.prizeList.addPrize?.quantity - 1);
          }}
        />
        {
          (form?.type?.target_id !== 'quiz_campaign' ||
          (form?.type?.target_id === 'quiz_campaign' &&
          form?.field_prize_distribution_type !== 'prize_stock' &&
          form?.field_prize_distribution_type !== 'result_based')) &&
          <>
            <StyledInput
              width={250}
              value={gameSettingsState?.prizeList.addPrize?.winner_redirect_link?.uri || ''}
              hasError={winnerURLError}
              hint="Please insert URL with http:// or https://"
              required
              size="large"
              label="Winner’s Redirect URL"
              // hint="How many units of this prize are available"
              placeholder="Insert your text here"
              disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
              onChange={(e) => {
                if (e.currentTarget.value && !regexHTTP.test(e.currentTarget.value)) {
                  setWinnerURLError(true);
                } else {
                  setWinnerURLError(false);
                }
                changeGameSettingsState('winner_redirect_link', {
                  ...formPrize?.winner_redirect_link,
                  uri: e.currentTarget.value,
                  title: gameSettingsState?.prizeList?.addPrize?.winner_redirect_link?.title,
                });
              }}
            />
            <StyledInput
              value={gameSettingsState?.prizeList.addPrize?.winner_redirect_link?.title || ''}
              required
              size="large"
              label="Link Text"
              hint="The winner redirect link."
              placeholder="Insert your text here"
              disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
              onChange={(e) =>
                changeGameSettingsState('winner_redirect_link', {
                  ...formPrize?.winner_redirect_link,
                  title: e.currentTarget.value,
                  uri: gameSettingsState?.prizeList?.addPrize?.winner_redirect_link?.uri,
                })
              }
            />
          </>
        }
        {form?.type?.target_id === 'spinning_wheel' && (
          <>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Tooltip text={'The selected colors are the ones that will appear in the game'}>
            <HelpCircle size='medium' />
            </Tooltip>
            <StyledInput
              width={'175px'}
              type="color"
              value={
                gameSettingsState?.prizeList.addPrize?.field_spin_prize_bg_color?.color || '#000'
              }
              required
              size="large"
              label="Prize background color*"
              disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
              onChange={(e) =>
                changeGameSettingsState('field_spin_prize_bg_color', {
                  color: e.currentTarget.value,
                  opacity: 1,
                })
              }
              hasError={
                hasEmptyField &&
                !gameSettingsState?.prizeList.addPrize?.field_spin_prize_bg_color?.color
              }
              errorText="Prize background color is required."
            />
            <StyledInput
              width={'175px'}
              type="color"
              value={
                gameSettingsState?.prizeList.addPrize?.field_spin_prize_text_color?.color || '#000'
              }
              required
              size="large"
              label="Prize text color*"
              disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
              onChange={(e) =>
                changeGameSettingsState('field_spin_prize_text_color', {
                  color: e.currentTarget.value,
                  opacity: 1,
                })
              }
              hasError={
                hasEmptyField &&
                !gameSettingsState?.prizeList.addPrize?.field_spin_prize_text_color?.color
              }
              errorText="Prize text color is required."
            />
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Tooltip text={'This icon will be applied to the slice of the prize. Is not mandatory'}>
            <HelpCircle size='medium' />
            </Tooltip>
            <StyledFileUploader
              value={gameSettingsState?.prizeList.addPrize?.image}
              width="1%"
              title="Prize thumbnail"
              accept=".png,.jpg,.jpeg"
              maxFileSize={102400}
              message="One file only.
              100 KB limit.
              Allowed types: png jpg jpeg.
              Images must be exactly 290x188 pixels."
              error={
                fileError && {
                  message: 'The selected file cannot be uploaded.',
                }
              }
              onChange={(file) => {}}
              onDrop={(e) => {
                verifiedFile(e);
              }}
              onClean={(file) => {
                setFileError(false);
                setGameSettingsState({
                  ...gameSettingsState,
                  prizeList: {
                    ...gameSettingsState.prizeList,
                    addPrize: {
                      ...gameSettingsState.prizeList.addPrize,
                      image: [],
                      field_image: null,
                    },
                  },
                });
              }}
              type="image"
            />
            </div>
          </>
        )}
        {(form?.type?.target_id === 'penalty_kick' || form?.type?.target_id === 'instant_card' || form?.type?.target_id === 'bee_run' || form?.type?.target_id === 'quiz_campaign') && (
          <div style={{ display: 'inline-flex', width: '100%' }}>
            <StyledTextarea
              label="Description"
              placeholder="Insert your text here"
              disabled={checkTimeWindow(gameSettingsState?.prizeList?.addPrize)}
              value={gameSettingsState?.prizeList.addPrize?.prize_description || ''}
              width={'65%'}
              maxLength={150}
              characterCounter
              style={{
                height: '90px',
                resize: 'none',
              }}
              onChange={(e) => changeGameSettingsState('prize_description', e.currentTarget.value)}
            />
          </div>
        )}
        <FlexContainer
          display="flex"
          justifySelf="flex-end"
          flexDirection="row"
          gap="0.5rem"
          position="absolute"
          bottom={0}
          right={'8px'}
        >
          <StyledButton
            leading
            disabled={loading}
            icon={Plus}
            css={{
              justifySelf: 'flex-end',
            }}
            size="medium"
            onClick={() => handleSavePrize()}
          >
            {gameSettingsState.prizeList.editMode ? 'Save' : 'Add prize'}
          </StyledButton>
          {gameSettingsState.prizeList.editMode && (
            <StyledButton
              variant="secondary"
              onClick={() => {
                setGameSettingsState({
                  ...gameSettingsState,
                  prizeList: {
                    ...gameSettingsState.prizeList,
                    editMode: false,
                  },
                });
                handleClear();
              }}
            >
              Cancel
            </StyledButton>
          )}
        </FlexContainer>
      </Grid.Item>
      <StyledTable
        maxWidth={width < 1200 ? '' : isAugmentedScale ? '58.3vw' : ''}
        data={
          form?.type?.target_id === 'spinning_wheel'
            ? InsertBgColor(filteredPrizeData)
            : filteredPrizeData
            ? form?.type?.target_id === 'quiz_campaign' &&
            (form?.field_prize_distribution_type === 'prize_stock' || form?.field_prize_distribution_type === 'result_based')
            ? filteredPrizeData.map(prize => ({
                ...prize,
                quantity: prize.initial_quantity
              }))
            : filteredPrizeData
            : []
        }
        tableWidth="100%"
        loading={loading || deleteModalState.loading ? true : false}
        columns={form?.type?.target_id === 'spinning_wheel' ? HeadersSpinning : Headers}
      />
    </Grid.Item>
  );
};
export default TabPrizeList;
