import { Card, FileValidated, Grid, Heading, Paragraph, Tooltip } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import StyledInput from '../../components/Input/Input';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { base_url } from '../../utils/constants';
import StyledButton from '../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTextarea from '../../components/Textarea/Textarea';
import useQuizHandler from '../../hook/useQuizHandler';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';
import { HelpCircle } from '@hexa-ui/icons';

type ResultType = {
  uuid: string;
  type: string;
  resultType: string;
  name: string;
  points?: {
    from: number;
    to: number;
  }
  description: string;
  cover_image: FileValidated[];
}

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const AddResult: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [formResult, setFormResult] = useState<ResultType>({
    uuid: state.campaign_uuid,
    type: state.type,
    resultType: state.type === 'trivia' ? 'range' : 'score',
    name: '',
    points: state.type === 'trivia' ? {
      from: 0,
      to: 0
    } : null,
    description: '',
    cover_image: [],
  });
  const { createResult } = useQuizHandler();

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const trivia = state.type === 'trivia';

  const sizeValidation = (file) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFormResult({ ...formResult, cover_image: e });
    }
  };

  const handleSave = async () => {
    if (!formResult?.name) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    setHasEmptyField(false);

    await createResult({
      uuid: formResult.uuid,
      type: formResult.type,
      resultType: formResult.resultType,
      name: formResult.name,
      points: {
        from: formResult.points?.from,
        to: formResult.points?.to
      },
      description: formResult.description,
      cover_image: formResult.cover_image
    }).then((res) => {
      console.log(res, res?.data?.errors)
      if (res?.data?.errors) {
        addToast({
          message: 'An error occurred while creating the result.',
          type: 'error',
        });
      } else {
        addToast({
          message: 'Result successfully created.',
          type: 'success',
        });
        navigate(-1);
      }
    })
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Add {trivia ? 'Range' : 'Score'}
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <SearchDropdown
                    width=""
                    label="Quiz*"
                    hint="&nbsp;"
                    value={state.campaign_name}
                    disabled={true}
                    placeholder=""
                    onSearch={() => {
                      throw new Error('Function not implemented.')
                    }}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Title*"
                    width={''}
                    hint="The name of the Ab InBev Result entity."
                    value={formResult?.name}
                    onChange={(e) => setFormResult({ ...formResult, name: e.currentTarget.value })}
                    errorText='Name is required'
                    hasError={hasEmptyField && !formResult?.name}
                    placeholder=""
                  />
                </StyledPaddding>
                {trivia && (
                  <StyledPaddding>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <Tooltip text={'This refers to the range used to determine if a user qualifies for a particular result. Assign an answer score, you can use positive or negative values.'}>
                        <HelpCircle size="medium" />
                      </Tooltip>
                      <div style={{ paddingBottom: '0.15rem' }} >
                        <Paragraph size='small' weight='semibold'>
                          Points
                        </Paragraph>
                        <Paragraph size='xsmall' colortype='disabled'>
                          Points from -10 to 10
                        </Paragraph>
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <Tooltip text={'For example, if the range is set from 10 to 20, any user who achieves a score within this range will be associated with this result.'}>
                        <HelpCircle size="medium" />
                      </Tooltip>
                      <div style={{ display: 'flex', gap: '2rem' }}>
                        <QuantifierLabeled
                          value={formResult.points.from}
                          min={-10}
                          max={0}
                          title='From'
                          defaultValue={formResult.points.from}
                          onClickPlusButton={() => {
                            const newValue = formResult.points.from + 1;
                            if (newValue <= 0) {
                              setFormResult((prevState) => ({
                                ...prevState,
                                points: {
                                  ...prevState.points,
                                  from: newValue,
                                },
                              }));
                            }
                          }}
                          onClickMinusButton={() => {
                            const newValue = formResult.points.from - 1;
                            if (newValue >= -10) {
                              setFormResult((prevState) => ({
                                ...prevState,
                                points: {
                                  ...prevState.points,
                                  from: newValue,
                                },
                              }));
                            }
                          }}
                          onChange={(e) => {
                            let v = parseInt(e.currentTarget.value, 10);
                            if (isNaN(v)) {
                              v = 1;
                            }
                            if (v < -10) {
                              v = -10;
                            } else if (v > 0) {
                              v = 0;
                            }
                            setFormResult((prevState) => ({
                              ...prevState,
                              points: {
                                ...prevState.points,
                                from: v,
                              },
                            }));
                          }}
                        />
                        <QuantifierLabeled
                          value={formResult.points.to}
                          min={0}
                          max={10}
                          title='To'
                          defaultValue={formResult.points.to}
                          onClickPlusButton={() => {
                            const newValue = formResult.points.to + 1;
                            if (newValue <= 10) {
                              setFormResult((prevState) => ({
                                ...prevState,
                                points: {
                                  ...prevState.points,
                                  to: newValue,
                                },
                              }));
                            }
                          }}
                          onClickMinusButton={() => {
                            const newValue = formResult.points.to - 1;
                            if (newValue >= 0) {
                              setFormResult((prevState) => ({
                                ...prevState,
                                points: {
                                  ...prevState.points,
                                  to: newValue,
                                },
                              }));
                            }
                          }}
                          onChange={(e) => {
                            let v = parseInt(e.currentTarget.value, 10);
                            if (isNaN(v)) {
                              v = 1;
                            }
                            if (v < 0) {
                              v = 0;
                            } else if (v > 10) {
                              v = 10;
                            }
                            setFormResult((prevState) => ({
                              ...prevState,
                              points: {
                                ...prevState.points,
                                to: v,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </StyledPaddding>
                )}
                <StyledPaddding>
                  <StyledTextarea
                    label="Description"
                    hint="&nbsp;"
                    placeholder="Insert your text here"
                    value={formResult?.description}
                    width={'100%'}
                    maxLength={150}
                    characterCounter
                    style={{
                      height: '90px',
                      resize: 'none',
                    }}
                    onChange={(e) => setFormResult({ ...formResult, description: e.currentTarget.value })}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Tooltip text={'An optional image associated with the result.'}>
                      <HelpCircle size="medium" />
                    </Tooltip>
                    <StyledFileUploader
                      value={formResult?.cover_image}
                      title="Image"
                      url={base_url + ''}
                      accept=".png,.jpg,.jpeg,.gif"
                      maxFileSize={1000000}
                      message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                      width="30vw"
                      onDrop={(e) => {
                        verifiedFile(e);
                      }}
                      onClean={(file) => {
                        setFileError(false);
                        setFormResult({
                          ...formResult,
                          cover_image: [],
                        });
                      }}
                    />
                  </div>
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default AddResult;
