import AlertContextProvider from './alert.context';
import ChallengeModalContext from './challenge.context';
import DeleteModalContextProvider from './deleteModal.context';
import GameSettingsContextProvider from './gameSettings.context';
import { QuizTypeProvider } from './quizType.context';

const GlobalContexts = ({ children }) => {
  return (
    <DeleteModalContextProvider>
      <ChallengeModalContext>
        <GameSettingsContextProvider>
          <QuizTypeProvider>
            <AlertContextProvider>{children}</AlertContextProvider>
          </QuizTypeProvider>
        </GameSettingsContextProvider>
      </ChallengeModalContext>
    </DeleteModalContextProvider>
  );
};

export default GlobalContexts;
