import { Tabs } from '@hexa-ui/components';
import React, { SetStateAction } from 'react';
import StyledTabs from '../../../../../components/Tabs/Tabs';
import { CampaignType } from '../../../../../store/dataTypes';
import TabChallenge from '../tabs/TabChallenge';
import TabGameSettingsSpinningWheel from '../tabs/TabCustomSettingsSpinWheel';
import TabLevels from '../tabs/TabLevels';
import TabLuckyNumber from '../tabs/TabLuckyNumbers';
import TabTimeWindow from '../tabs/TabTimeWindow';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
  game: string;
}
const SettingsTabSelect: React.FC<props> = ({ form, setForm, game }) => {
  return game === 'spinning_wheel' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger>
        <Tabs.Trigger value="2">Time Window</Tabs.Trigger>
        {form?.lucky_number_status ? <Tabs.Trigger value="3">Lucky Numbers</Tabs.Trigger> : <></>}
        {form?.rewards_integration ? <Tabs.Trigger value="4">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabTimeWindow />
      </Tabs.Content>
      <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLuckyNumber form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'penalty_kick' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger>
        <Tabs.Trigger value="2">Time Window</Tabs.Trigger>
        {form?.lucky_number_status ? <Tabs.Trigger value="3">Lucky Numbers</Tabs.Trigger> : <></>}
        {form?.rewards_integration ? <Tabs.Trigger value="4">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabTimeWindow />
      </Tabs.Content>
      <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLuckyNumber form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'bottle_crush' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">Levels</Tabs.Trigger>
        {form?.rewards_integration ? <Tabs.Trigger value="2">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLevels />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'instant_card' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger>
        <Tabs.Trigger value="2">Time Window</Tabs.Trigger>
        {form?.lucky_number_status ? <Tabs.Trigger value="3">Lucky Numbers</Tabs.Trigger> : <></>}
        {form?.rewards_integration ? <Tabs.Trigger value="4">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabTimeWindow />
      </Tabs.Content>
      <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLuckyNumber form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'virtual_cup_mug' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger>
        <Tabs.Trigger value="2">Time Window</Tabs.Trigger>
        <Tabs.Trigger value="3">Lucky Numbers</Tabs.Trigger>
        {form?.rewards_integration ? <Tabs.Trigger value="4">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabTimeWindow />
      </Tabs.Content>
      <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLuckyNumber form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'soccer_competition' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger>
        <Tabs.Trigger value="4">Challenges</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabTimeWindow />
      </Tabs.Content>
      <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabLuckyNumber form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'bee_run' ? (
    <StyledTabs defaultValue={/* form?.bee_run_prizes ? '1' : */ '2'}>
      <Tabs.List>
        {/* {form?.bee_run_prizes ? <Tabs.Trigger value="1">PDF Settings</Tabs.Trigger> : <></>} */}
        {form?.rewards_integration ? <Tabs.Trigger value="2">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabGameSettingsSpinningWheel form={form} setForm={setForm} />
      </Tabs.Content>
      <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : game === 'quiz_campaign' ? (
    <StyledTabs defaultValue="1">
      <Tabs.List>
        {form?.rewards_integration ? <Tabs.Trigger value="1">Challenge</Tabs.Trigger> : <></>}
      </Tabs.List>
      <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TabChallenge form={form} setForm={setForm} />
      </Tabs.Content>
    </StyledTabs>
  ) : (
    <div>
      <p>No game selected.</p>
    </div>
  );
};

export default SettingsTabSelect;
