import { Card, Grid, Heading, Select, ExpandableSection, FileValidated, Tooltip } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import StyledInput from '../../components/Input/Input';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import { base_url } from '../../utils/constants';
import StyledButton from '../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTextarea from '../../components/Textarea/Textarea';
import StyledSelect from '../../components/Select/Select';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';
import { changeState, changeStateForm } from '../../utils/functions';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import useQuizHandler from '../../hook/useQuizHandler';
import StyledToggle from '../../components/Toggle/Toggle';
import { HelpCircle } from '@hexa-ui/icons';

type QuestionType = {
  type: string;
  quizUuid: string;
  uuidEntity: string;
  questionType: string;
  question_id: string;
  question_name: string;
  question_description: string;
  question_cover_image: FileValidated[];
  field_question_cover_image: {
    image: string;
    id: string;
  };
  feedback_id: string;
  feedback_name: string;
  feedback_description: string;
  feedback_cover_image: FileValidated[];
  field_feedback_cover_image: {
    image: string;
    id: string;
  };
  answer_id: string;
  answer_name: string;
  answer_description: string;
  answer_cover_image: FileValidated[];
  field_answer_cover_image: {
    image: string;
    id: string;
  };
  result_id: string;
  result: string;
  answers_id: string;
  answer_title_simple: string;
  answer_trivia_image: FileValidated[];
  field_answer_trivia_image: {
    image: string;
    id: string;
  }
  correct_answer: boolean;
  points: number;
}

const StyledPadding = styled('div', {
  paddingBottom: '1rem',
});

const EditQuestion: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [search, setSearch] = useState(state.type === 'personality' ? state.field_answers.results.result_name : null);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const { editQuestion } = useQuizHandler();
  const [formQuestion, setFormQuestion] = useState<QuestionType>({
    type: state.type,
    quizUuid: state.campaign_uuid,
    uuidEntity: state.uuidEntity,
    questionType: state.field_type === 'one' ? '1st option' : 'Multiple choice',
    question_id: state.field_question.id,
    question_name: state.field_question.field_title,
    question_description: state.field_question.field_description,
    question_cover_image: state.field_question.image,
    field_question_cover_image: {
      image: state.field_question.image?.url || '',
      id: state.field_question.image?.id || ''
    },
    feedback_id: state.field_feedback?.id,
    feedback_name: state.field_feedback?.field_title,
    feedback_description: state.field_feedback?.field_description,
    feedback_cover_image: state.field_feedback?.image,
    field_feedback_cover_image: {
      image: state.field_feedback?.image?.url || '',
      id: state.field_feedback?.image?.id || ''
    },
    answer_id: state.field_answers.id,
    answer_name: state.field_answers.field_title,
    answer_description: state.field_answers.field_description,
    answer_cover_image: state.field_answers.image,
    field_answer_cover_image: {
      image: state.field_answers.image?.url || '',
      id: state.field_answers.image?.id || ''
    },
    result_id: state.type === 'personality' ? state.field_answers.results.result_id : null,
    result: state.type === 'personality' ? state.field_answers.results.result : null,
    answers_id: state.type === 'trivia' ? state.field_answers_trivia.id : null,
    answer_title_simple: state.type === 'trivia' ? state.field_answers_trivia.answer_title_simple : null,
    answer_trivia_image: state.type === 'trivia' ? state.field_answers_trivia.image : null,
    field_answer_trivia_image: {
      image: state.field_answers_trivia?.image?.url || '',
      id: state.field_answers_trivia?.image?.id || ''
    },
    correct_answer: state.type === 'trivia' ? state.field_answers_trivia.field_correct_answer : null,
    points: state.type === 'personality' ? state.field_answers.results.field_points : state.field_answers_trivia.field_points,
  });

  const trivia = state.type === 'trivia';

  const TypeItems = [{ name: '1st option' }, { name: 'Multiple choice' }];

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const sizeValidation = (file, field) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e, field) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e, field)) {
      setFileError(false);
      setFormQuestion({ ...formQuestion, [field]: e, [`field_${field}`]: { ...formQuestion[`field_${field}`], image: e } });
    }
  };

  const handleSave = async () => {
    if (!formQuestion?.question_name || !formQuestion?.answer_name || !trivia && !formQuestion?.result) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    setLoading(true);
    setHasEmptyField(false);

    await editQuestion({
      type: formQuestion.type,
      questionType: formQuestion.questionType === '1st option' ? 'one' : 'multiple',
      uuidEntity: formQuestion.uuidEntity,
      quizUuid: formQuestion.quizUuid,
      question_id: formQuestion.question_id,
      question_name: formQuestion.question_name,
      question_description: formQuestion.question_description,
      question_cover_image: formQuestion.question_cover_image,
      field_question_cover_image: {
        id: formQuestion.field_question_cover_image.id
      },
      feedback_id: formQuestion.feedback_id,
      feedback_name: formQuestion.feedback_name,
      feedback_description: formQuestion.feedback_description,
      feedback_cover_image: formQuestion.feedback_cover_image,
      field_feedback_cover_image: {
        id: formQuestion.field_feedback_cover_image.id
      },
      answer_id: formQuestion.answer_id,
      answer_name: formQuestion.answer_name,
      answer_description: formQuestion.answer_description,
      answer_cover_image: formQuestion.answer_cover_image,
      field_answer_cover_image: {
        id: formQuestion.field_answer_cover_image.id
      },
      result_id: formQuestion.result_id,
      result: formQuestion.result,
      answers_id: formQuestion.answers_id,
      answer_title_simple: formQuestion.answer_title_simple,
      answer_trivia_image: formQuestion.answer_trivia_image,
      field_answer_trivia_image: {
        id: formQuestion.field_answer_trivia_image.id
      },
      correct_answer: formQuestion.correct_answer,
      points: formQuestion.points,
    }).then((res) => {
      setLoading(false);
      console.log(res, res?.data?.errors)
      if (res?.data?.errors) {
        addToast({
          message: 'An error occurred while updating the question.',
          type: 'error',
        });
      } else {
        addToast({
          message: 'Question successfuly updated.',
          type: 'success',
        });
        navigate(-1);
      }
    })
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Edit {state.field_question.field_title}
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPadding>
                  <SearchDropdown
                    label="Quiz*"
                    hint="&nbsp;"
                    value={state.campaign_name}
                    disabled={true}
                    placeholder=""
                    onSearch={() => Promise.resolve([])}
                  />
                </StyledPadding>
                <StyledPadding>
                  <StyledSelect
                    error={''}
                    value={formQuestion.questionType}
                    size="large"
                    label="Type*"
                    width={'30%'}
                    onChange={(e) => setFormQuestion({ ...formQuestion, questionType: e })}
                  >
                    {TypeItems &&
                      TypeItems.map((item, index: number) => {
                        return (
                          <Select.Option key={item.name + index} value={String(item.name)}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </StyledSelect>
                </StyledPadding>
                <StyledPadding>
                  <ExpandableSection defaultOpen open={!loading} headerText={'Questions*'}>
                    <StyledPadding>
                      <StyledInput
                        size="large"
                        label="Title*"
                        placeholder=""
                        value={formQuestion.question_name}
                        onChange={(e) => setFormQuestion({ ...formQuestion, question_name: e.currentTarget.value })}
                        errorText='Question title is required'
                        hasError={hasEmptyField && !formQuestion.question_name}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledTextarea
                        label="Description"
                        hint="&nbsp;"
                        placeholder="Insert your text here"
                        value={formQuestion.question_description}
                        maxLength={150}
                        characterCounter
                        onChange={(e) => setFormQuestion({ ...formQuestion, question_description: e.currentTarget.value })}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <ImageUploader
                        value={formQuestion.field_question_cover_image.image}
                        title="Image"
                        accept=".png,.jpg,.jpeg,.gif"
                        maxFileSize={26214400}
                        message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                        error={ fileError && {
                          message: 'The selected file cannot be uploaded.',
                        }}
                        onDrop={(e) => {
                          verifiedFile(e, 'question_cover_image');
                        }}
                        onClean={(file) => {
                          setFileError(false);
                          setFormQuestion({
                            ...formQuestion,
                                question_cover_image: [],
                                field_question_cover_image: { ...formQuestion.field_question_cover_image, image: '' }
                          });
                        }}
                        url={base_url + formQuestion.field_question_cover_image.image}
                        id={'cover_image'}
                      />
                    </StyledPadding>
                  </ExpandableSection>
                </StyledPadding>
                <StyledPadding>
                  <ExpandableSection defaultOpen open={!loading} headerText="Feedback">
                    <StyledPadding>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Tooltip text={'The feedback title field is shown after the user completes the quiz.'}>
                          <HelpCircle size="medium" />
                        </Tooltip>
                        <StyledInput
                          size="large"
                          label="Title"
                          placeholder=""
                          width={'100%'}
                          value={formQuestion.feedback_name}
                          onChange={(e) => setFormQuestion({ ...formQuestion, feedback_name: e.currentTarget.value })}
                        />
                      </div>
                    </StyledPadding>
                    <StyledPadding>
                      <StyledTextarea
                        label="Description"
                        hint="&nbsp;"
                        placeholder="Insert your text here"
                        value={formQuestion.feedback_description}
                        maxLength={150}
                        characterCounter
                        onChange={(e) => setFormQuestion({ ...formQuestion, feedback_description: e.currentTarget.value })}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <ImageUploader
                        value={formQuestion.field_feedback_cover_image.image}
                        title="Image"
                        accept=".png,.jpg,.jpeg,.gif"
                        maxFileSize={26214400}
                        message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                        error={ fileError && {
                          message: 'The selected file cannot be uploaded.',
                        }}
                        onDrop={(e) => {
                          verifiedFile(e, 'feedback_cover_image');
                        }}
                        onClean={(file) => {
                          setFileError(false);
                          setFormQuestion({
                            ...formQuestion,
                                feedback_cover_image: [],
                                field_feedback_cover_image: { ...formQuestion.field_feedback_cover_image, image: '' }
                          });
                        }}
                        url={base_url + formQuestion.field_feedback_cover_image.image}
                        id={'cover_image'}
                      />
                    </StyledPadding>
                  </ExpandableSection>
                </StyledPadding>
                  <StyledPadding>
                    <ExpandableSection defaultOpen open={!loading} headerText="Answers*">
                      <StyledPadding>
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                          <Tooltip text={'The options provided to the user to choose from in response to the question. Allows you to create multiple values.'}>
                            <HelpCircle size="medium" />
                          </Tooltip>
                          <StyledInput
                            size="large"
                            label="Title*"
                            width={'100%'}
                            value={formQuestion.answer_name}
                            onChange={(e) => setFormQuestion({ ...formQuestion, answer_name: e.currentTarget.value })}
                            placeholder=""
                            errorText='Answer title is required'
                            hasError={hasEmptyField && !formQuestion?.answer_name}
                          />
                        </div>
                      </StyledPadding>
                      <StyledPadding>
                        <StyledTextarea
                          label="Description"
                          hint="&nbsp;"
                          placeholder="Insert your text here"
                          value={formQuestion.answer_description}
                          maxLength={150}
                          characterCounter
                          onChange={(e) => setFormQuestion({ ...formQuestion, answer_description: e.currentTarget.value })}
                        />
                      </StyledPadding>
                      <StyledPadding>
                        <ImageUploader
                          value={formQuestion.field_answer_cover_image.image}
                          title="Image"
                          accept=".png,.jpg,.jpeg,.gif"
                          maxFileSize={26214400}
                          message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                          error={ fileError && {
                            message: 'The selected file cannot be uploaded.',
                          }}
                          onDrop={(e) => {
                            verifiedFile(e, 'answer_cover_image');
                          }}
                          onClean={(file) => {
                            setFileError(false);
                            setFormQuestion({
                              ...formQuestion,
                                  answer_cover_image: [],
                                  field_answer_cover_image: { ...formQuestion.field_answer_cover_image, image: '' }
                            });
                          }}
                          url={base_url + formQuestion.field_answer_cover_image.image}
                          id={'cover_image'}
                        />
                      </StyledPadding>
                      {!trivia ?
                        <StyledPadding>
                          <AutoComplete
                            onChange={(e) => {
                              changeState(setSearch, e.currentTarget.value);
                            }}
                            onClear={() => {
                              changeState(setSearch, '');
                              changeStateForm(setFormQuestion, formQuestion, 'result', undefined);
                            }}
                            onClickResultResultsMode={(result) => {
                              setFormQuestion({
                                ...formQuestion,
                                result: result?.uuidEntity,
                              });
                            }}
                            resultsMode={true}
                            value={search}
                            editValue={search}
                            required
                            size="large"
                            label="Result*"
                            placeholder="Start typing to search"
                            errorText='Result is required'
                            hasError={hasEmptyField && !formQuestion?.result}
                          />
                        </StyledPadding>
                        :
                        <>
                          <StyledPadding>
                            <StyledInput
                              size="large"
                              label="Title simple"
                              value={formQuestion.answer_title_simple}
                              onChange={(e) => setFormQuestion({ ...formQuestion, answer_title_simple: e.currentTarget.value })}
                              placeholder=""
                            />
                          </StyledPadding>
                          <StyledPadding>
                            <ImageUploader
                              value={formQuestion.field_answer_trivia_image.image}
                              title="Image"
                              accept=".png,.jpg,.jpeg,.gif"
                              maxFileSize={26214400}
                              message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                              error={ fileError && {
                                message: 'The selected file cannot be uploaded.',
                              }}
                              onDrop={(e) => {
                                verifiedFile(e, 'answer_trivia_image');
                              }}
                              onClean={(file) => {
                                setFileError(false);
                                setFormQuestion({
                                  ...formQuestion,
                                      answer_trivia_image: [],
                                      field_answer_trivia_image: { ...formQuestion.field_answer_trivia_image, image: '' }
                                });
                              }}
                              url={base_url + formQuestion.field_answer_trivia_image.image}
                              id={'cover_image'}
                            />
                          </StyledPadding>
                          <StyledPadding>
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                              <Tooltip text={'You can indicate if the provided answer is the correct one.'}>
                                <HelpCircle size="medium" />
                              </Tooltip>
                              <StyledToggle
                                title="Correct Answer"
                                description="Utilize this field when crafting a question with only one correct option. When activated, it signifies that the designated answer is indeed the correct one."
                                value={formQuestion.correct_answer}
                                onChange={(val: boolean) => {
                                  changeStateForm(setFormQuestion, formQuestion, 'correct_answer', val);
                                }}
                              />
                            </div>
                          </StyledPadding>
                        </>
                      }
                    <StyledPadding>
                      <div style={{ width: '35%' }}>
                        <QuantifierLabeled
                          title="Points*"
                          defaultValue={formQuestion?.points}
                          value={formQuestion?.points}
                          min={0}
                          onChange={(e) => {
                            let v = parseInt(e.currentTarget.value, 10);
                            if (isNaN(v)) {
                              v = 1;
                            }
                            v = v <= 0 ? 1 : v;
                            changeStateForm(setFormQuestion, formQuestion, 'points', v);
                          }}
                          onClickPlusButton={() => {
                            changeStateForm(setFormQuestion, formQuestion, 'points', formQuestion.points + 1)
                          }}
                          onClickMinusButton={() => {
                            changeStateForm(setFormQuestion, formQuestion, 'points', formQuestion.points - 1)
                          }}
                        />
                      </div>
                    </StyledPadding>
                  </ExpandableSection>
                </StyledPadding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default EditQuestion;
