import { createContext, useContext, useState } from 'react';

interface QuizTypeContextValue {
  quizType: string | null;
  setQuizType: (quizType: string | null) => void;
}

const QuizTypeContext = createContext<QuizTypeContextValue>({
  quizType: null,
  setQuizType: () => {},
});

const useQuizType = () => {
  return useContext(QuizTypeContext);
};

const QuizTypeProvider = ({ children }) => {
  const [quizType, setQuizTypeState] = useState<string | null>(() => {
    const savedQuizType = sessionStorage.getItem('quizType');
    return savedQuizType ? JSON.parse(savedQuizType) : null;
  });

  const setQuizType = (quizType: string | null) => {
    setQuizTypeState(quizType);
    if (quizType) {
      sessionStorage.setItem('quizType', JSON.stringify(quizType));
    } else {
      sessionStorage.removeItem('quizType');
    }
  };

  return (
    <QuizTypeContext.Provider value={{ quizType, setQuizType }}>
      {children}
    </QuizTypeContext.Provider>
  );
};

export { QuizTypeContext, useQuizType, QuizTypeProvider };
