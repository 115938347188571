import { Quantifier, Paragraph } from '@hexa-ui/components';

type QuantifierLabeledProps = {
  labelText?: string;
  title: string;
  value: number;
  defaultValue: number;
  min: number;
  max?: number;
  onClickPlusButton: () => void;
  onClickMinusButton: () => void;
  onChange: (value) => void;
};

const QuantifierLabeled = ({labelText, title, value, defaultValue, min, max, onClickPlusButton, onClickMinusButton, onChange}: QuantifierLabeledProps) => {
  return (
    <div>
        <Paragraph weight='semibold' size='small' css={{ marginBottom: '0.2rem'}}>{title}</Paragraph>
        {labelText && <Paragraph weight='normal' size='xsmall' colortype='disabled' css={{ marginBottom: '1.3rem' }}>{labelText}</Paragraph>}
        <Quantifier value={value} onChange={onChange} defaultValue={defaultValue} min={min} max={max} onClickPlusButton={onClickPlusButton} onClickMinusButton={onClickMinusButton}/>
    </div>
  );
}

export default QuantifierLabeled
