import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable from '../../../components/Table/Table';
import { useAppSelector } from '../../../store';
import { changeState } from '../../../utils/functions';
import QuizzesHeaders from '../../json/columnHeaders/QuizzesHeadersCampaignDetails.json'
import QuizData from '../../json/mock/mockingQuizzesCampaignData.json'
interface props {
  game;
}
const QuizzesTab: React.FC<props> = ({ game }) => {
  const params = useParams();
  const campaignUuid: string = useAppSelector((state) => state.campaignDetails.data?.uuid);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [requestData, setRequestData] = useState({
    id: params?.id,
    page,
    limit,
    search,
    game
  });
  const [filteredData, setFilteredData] = useState([])

  const navigate = useNavigate();

  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 250),
    []
  );

  const handleSearch = (value) => {
    if (value.length == 0 || value.length >= 2) {
      setPage(1);
      requestData.search = value;
      setRequestData(requestData);
      setLoading(true);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    changeState(setSearch, event.currentTarget.value);

    debouncedSearch(event.currentTarget.value);
  };

  useEffect(() => {
    setFilteredData(InsertActions(QuizData))
  }, [])


  const InsertActions = (data) => {
    let aux = [];

    const actions = (prize) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => {
            navigate(
              `/bees-games/manage-assets/campaign-details/${params.id}/edit-quiz/${prize.id}`,
              {
                state: {
                  game,
                },
              }
            );
          }}
        />
        <Modal.Root
          actions={
            <FlexContainer
              width="100%"
              display="inline-flex"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Modal.Action>
                <StyledButton variant="secondary">Cancel</StyledButton>
              </Modal.Action>
              <Modal.Action>
                <StyledButton variant="destructive" onClick={() => ('')}>
                  Delete
                </StyledButton>
              </Modal.Action>
            </FlexContainer>
          }
          trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
          variant="overlay"
          destructive="critical"
        >
          <Heading type="H3" css={{ marginBottom: '1rem' }}>
            Delete prize?
          </Heading>
          <Paragraph>{'Do you really wish to delete this prize?'}</Paragraph>
          <Paragraph>{'This action can’t be undone.'}</Paragraph>
        </Modal.Root>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let publishedColor = '';
      item.published === 'Off' ? (publishedColor = '#E4423F') : (publishedColor = '#00A838');

      aux[i] = Object.assign({}, item, {
        actions: actions(item),
        published: <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '10rem',
            backgroundColor: publishedColor,
          }}
        />
        <div>{item.published}</div>
      </div>,
      });
    }

    return aux;
  };

  return (
    <>
    <Card elevated={'medium'} border={'large'} css={{ padding: '0px', position: 'relative' }}>
      <FlexContainer
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding="1rem"
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={handleChange}
            onClear={(e) => {
              setSearch('');
              handleSearch('');
            }}
          />
        </div>
        <StyledButton
          variant="primary"
          icon={Plus}
          leading
          isLoading={loading}
          onClick={() =>
            navigate(`/bees-games/manage-assets/campaign-details/${params.id}/add-quiz`, {
              state: {
                id: params.id,
                game,
                campaignUuid
              },
            })
          }
        >
          Add Quiz
        </StyledButton>
      </FlexContainer>
      <StyledTable
        loading={loading}
        columns={QuizzesHeaders}
        data={filteredData}
        pagination={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20],
          showPageSizeSelector: true,
        }}
      />
    </Card>
    </>
  );
};

export default QuizzesTab;
