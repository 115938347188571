import { Card, Heading, IconButton, Modal, Paragraph, Tooltip } from '@hexa-ui/components';
import { Edit2, HelpCircle, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import handleFilter from '../../campaignDetails/extra/filter';
import ResultHeaders from '../../json/columnHeaders/QuizResultsHeaders.json';
import PageTitle from '../../../components/PageTitle/PageTitle';
import useQuizHandler from '../../../hook/useQuizHandler';
import { useQuizType } from '../../../contexts/quizType.context';

const ResultsTab: React.FC = () => {
  const navigate = useNavigate();

  const { quizType } = useQuizType();
  const params = useParams();
  const { id } = params;
  const [data, setData] = useState<any>();
  const [quizData, setQuizData] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { getResults, deleteResult, getQuiz } = useQuizHandler();
  const resultType = quizType === 'trivia' ? 'range' : 'score';
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: '',
    id: id,
    type: quizType,
    resultType: resultType
  })

  const fetchData = async () => {
    setLoading(true);

    try {
      const [resultsRes, quizRes] = await Promise.all([
        getResults(requestData),
        getQuiz(requestData)
      ]);

      const formatData = resultsRes.data.map((item) => {
        const includedItem = resultsRes.included?.find(included => included.id === item.relationships.field_image.data?.id);

        return {
          drupal_internal__id: item.attributes.drupal_internal__id,
          type: quizType,
          resultType: resultType,
          name: item.attributes.name,
          points: quizType === 'trivia' ? {
            from: item.attributes.field_points.from,
            to: item.attributes.field_points.to,
          } : null,
          field_description: item.attributes.field_description,
          uuidEntity: item.id,
          field_image: {
            image: includedItem ? includedItem.attributes.uri.url : null,
            id: includedItem ? includedItem.id : null,
          },
          campaign_name: quizRes.data[0]?.attributes.name,
          campaign_uuid: quizRes.data[0]?.id
        };
      });

      setQuizData({
        campaign_name: quizRes.data[0]?.attributes.name,
        campaign_uuid: quizRes.data[0]?.id
      });

      const updateData = InsertActions(formatData);

      setData(updateData);
      setFilteredData(updateData);

      setRequestData(prevState => ({ ...prevState, total: formatData.length }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleDelete(uuidEntity, resultType) {
    deleteResult(uuidEntity, resultType).finally(() => fetchData());
  }

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  const handleSearch = (e) => {
    const searchValue = e.currentTarget.value;
    setSearch(searchValue);

    if (searchValue) {
      const searchKeys = ['drupal_internal__id', 'name', 'description'];
      handleFilter(searchValue, data, searchKeys, setFilteredData);
    } else {
      setFilteredData(data);
    }
  };

  const InsertActions = (data) => {
    let aux = [];

    const actions = (data) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(
                `/bees-games/quiz/quiz-details/${id}/edit-result/${data.drupal_internal__id}`,
                {
                  state: data,
                }
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleDelete(data.uuidEntity, data.resultType)}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete result?
            </Heading>
            <Paragraph>{'Do you really wish to delete this result?'}</Paragraph>
            <Paragraph>{"This action can't be undone."}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Tooltip text={'They must be created before adding the questions so that they can be associated when creating the question.'}>
          <HelpCircle style={{ marginTop: '0.5rem' }} size="medium" />
        </Tooltip>
        <PageTitle marginBottom="1rem" size="H2" title="Results" />
      </div>
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={handleSearch}
              onClear={handleSearch}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            isLoading={loading}
            onClick={() => navigate(`/bees-games/quiz/quiz-details/${requestData.id}/add-result`,
              {
                state: {
                  campaign_name: quizData.campaign_name,
                  campaign_uuid: quizData.campaign_uuid,
                  type: quizType,
                  resultType: resultType
                }
              }
            )}
          >
            Add Result
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={ResultHeaders}
          search={false}
          data={filteredData || []}
          loading={loading}
          pagination={false}
        />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
};

export default ResultsTab;
